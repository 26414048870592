import { Component } from '@angular/core';

@Component({
  templateUrl: 'coreui-icons.component.html'
})
export class CoreUIIconsComponent {

  constructor() { }

}
