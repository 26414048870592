import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments";
import { DateTime } from "luxon";
import _, { differenceBy } from "lodash";
import { ScheduledTournament } from "../interfaces/scheduled-tournament";
import { CopyTypeEnum } from "../views/scheduled-tournaments/scheduled-tournaments.component";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  private skipListSubject = new BehaviorSubject<any[]>(
    JSON.parse(localStorage.getItem("scheduleSkipList") || "[]")
  );

  public skipList$: Observable<any[]> = this.skipListSubject.asObservable();

  baseURL = environment.API_URL + "schedule/";
  baseURLv2 = environment.API_URL + "v2/schedule/";

  constructor(private http: HttpClient) {}

  getToken() {
    return new Date();
  }

  createSchedule(body: {
    id: number[];
    notification: object[];
    priority: number;
    comment: string;
    rebuys: object;
    anticipate_notification: boolean;
    ignore_warnings: boolean;
  }): Observable<any> {
    return this.http.post<any>(this.baseURL + "create-schedule", body, {
      observe: 'response'
    })
  }

  updateSchedule(schedules: number[], priority: number, replicate: boolean = true) {
    return this.http.put(this.baseURLv2 + "update", {
      schedules,
      priority,
      replicate
    });
  }

  getOrganizedSchedules(order: string[][]): Observable<any[]> {
    let url = this.baseURL + "get-organized-schedules"
    return this.http.get<any[]>(url, {
      params: {
        order: order.map(e => e.join(':')).join(','),
      },
      responseType: 'json'
    });
  }

  deleteScheduleById(tiers: any, replicate: any, flag_tournaments: boolean, ...id: number[]): Observable<{
    affectedRows: number;
    id: string[];
  }> {
    return this.http.request<any>('DELETE', this.baseURL + "delete-schedule", {
      body : {
        schedules: id,
        flag_tournaments: flag_tournaments
      },
      params: {
        replicate: replicate,
        tiers: tiers
      },
    });
  }

  public addToSkipList(tournament: any): void {
    const skipList: Array<any> = this.skipListSubject.value;

    const today = new Date()
    tournament.map((item) => {
      skipList.push({ id: item.id, day: today.toDateString() });
    })
    localStorage.setItem("scheduleSkipList", JSON.stringify(skipList));
    this.skipListSubject.next(skipList);

  }

  public removeSkippedTournaments(
    tournamentList: any[]
  ): any[] {
    const listWithoutSkipped = differenceBy(
      tournamentList,
      this.skipListSubject.value,
      "id"
    );

    return listWithoutSkipped;
  }

  public clearSkippedList(): void {
    const clearExpired = this.skipListSubject.value.filter((item: any) => {
      const today = new Date();
      const day = new Date(item.day)
      return today.getDay() === day.getDay();
    });
    localStorage.setItem("scheduleSkipList", JSON.stringify(clearExpired));
  }

  public prepareSoftRefresh(tournamentList: any[]): any {
    let refreshList = new Map();
    tournamentList.forEach((e) => {
      refreshList.set(e.id, e.current_blind);
    })
    return refreshList;
  }

  copyTournaments(tierId: number, listId: string, listDays: string, copyType:CopyTypeEnum) {
    return this.http.post(this.baseURL + "copy-tournaments", {
      tier: tierId,
      tournaments: listId,
      to_weekdays: listDays,
      copyType
    })
  }

  setExceptions(userIds, scheduleIds) {
    return this.http.put(this.baseURL + "set-exceptions", {
      users: userIds,
      schedules: scheduleIds,
    })
  }

  saveRegisterTime(schedules: number[], timeInMinutes: number, replicate: boolean): Observable<any> {
    return this.http.put(this.baseURLv2 + "meta/player-start", {schedules, time: timeInMinutes, replicate})
  }

  deleteRegisterTime(ids: number[]): Observable<any> {
    return this.http.delete<any>(this.baseURLv2 + "meta/player-start" + ids);
  }

  saveComment(schedules: number[], comment: string, replicate: boolean): Observable<any> {
    return this.http.put(this.baseURLv2 + "meta/comment", {schedules, comment, replicate})
  }

  saveFavorite(schedules: number[], unfavorite: boolean): Observable<any> {
    return this.http.put(this.baseURLv2 + "meta/favorite", {schedules, unfavorite})
  }

  getMaxRebuy(): Observable<any> {
    return this.http.get(this.baseURLv2 + "meta/rebuy")
  }

  saveMaxRebuy(schedules: number[], rebuy: number, replicate: boolean,): Observable<any> {
    return this.http.put(this.baseURLv2 + "meta/rebuy", {schedules, replicate, rebuy})
  }

  getRebalanceList(body, page: number = 0): Observable<any> {
    return this.http.post(this.baseURLv2 + "meta/as-exclude", body,
    {
      params: {
        page: page.toString()
      }
    })
  }

  ignoreRebalance(schedules: number[], replicate: boolean): Observable<any> {
    return this.http.put(this.baseURLv2 + "meta/as-exclude", {schedules, replicate})
  }

}
