import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, share } from "rxjs/operators";
import { environment } from '../../environments';
import { UserLicense, UserSubscription } from '../interfaces/user';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  baseURL = environment.API_URL + "v2/license/"
  baseShopURL = environment.API_URL + "v2/shop/"

  constructor(private http: HttpClient, private user: UserService) { }

  private static lastLicense: Promise<UserLicense>;

  getLicense(user_id: number | null = null): Promise<UserLicense> {
    if(!user_id && LicenseService.lastLicense != null) {
      return LicenseService.lastLicense;
    }
    const params = {}
    if (user_id) {
      params['user_id'] = user_id
    }
    const l = this.http.get<UserLicense>(this.baseURL + "get-user-license", { params }).pipe(
      map((e) => {
        let date = new Date(e.expiration)
        e.expiration = date.toISOString().split('T')[0];
        return e;
      }),
      share(),
    ).toPromise();
    if (!user_id) {
      LicenseService.lastLicense = l;
    }
    return l;
  }

  updateLicense(body: { user_id: number; license_type: string; slots: number; expiration: string }): Observable<any> {
    return this.http
      .post(this.baseURL + "add-user-license", body, {
        observe: "response",
      })
      .pipe(
        map((e) => {
          if (e.status == 200) {
            return { ok: true };
          } else {
            return { ok: false, msg: e.body["error"] };
          }
        })
      );
  }

  async isFreeLocked(): Promise<boolean> {
    const user = await this.user.getLastUser();
    if(!user.role.includes('MASTER')) {
      return false;
    }
    const licenseData = await this.getLicense();
    const license = licenseData.license;
    return !license || license == 'free';
  }

  async isFullLocked(): Promise<boolean> {
    const license = await this.getLicense();
    return license.license != 'full';
  }

  checkoutUrl() {
    return this.baseShopURL + "subscribe";
  }

  subscriptionPortalUrl(): string {
    return this.baseShopURL + "edit-subscription"
  }

  private static lastCurrentSub: Promise<UserSubscription>;

  currentSubscription() {
    if(LicenseService.lastCurrentSub != null) {
      return LicenseService.lastCurrentSub;
    }
    const v = this.http.get<UserSubscription>(this.baseShopURL + "license").toPromise();
    LicenseService.lastCurrentSub = v;
    return v;
  }

}
