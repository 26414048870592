import { Component, OnInit } from "@angular/core";
import { LicenseService } from "../../services/license.service";
import { Router } from "@angular/router";

@Component({
  templateUrl: "invalid-tier-license.component.html",
})
export class InvalidTierLicense implements OnInit {
  constructor(public licenseService: LicenseService, public router: Router) {}

  async ngOnInit() {
    const license = await this.licenseService.getLicense();
    if (license.license == "") {
      if (license.self) {
        this.router.navigate(["/buy"]);
      }
    } else {
        this.router.navigate(["/"]);
    }
  }
}
