import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';

@Pipe({
  name: "withLoading",
})
export class WithLoadingPipe implements PipeTransform {
  transform(val) {
    return isObservable(val)
      ? val.pipe(
          map((value: any) => ({ loading: false, value })),
          startWith({ loading: true }),
          catchError((error) => of({ loading: false, error }))
        )
      : { loading: false, val };
  }
}

@Pipe({
  name: "withMultiLoading",
})
export class WithMultipleLoadingPipe implements PipeTransform {
  transform(val) {
    return isObservable(val)
      ? val.pipe(
        map((value: any) => ({
          loading: value.type === 'start',
          value: value.type ? value.value : value
        })),
        startWith({ loading: true }),
        catchError(error => of({ loading: false, error }))
      )
      : { loading: false, val };
  }
}

export function loading() {
  return of({ type: 'start' });
}

export function wrapDoneValue<T>(): ((_: Observable<T>) => Observable<{ type: 'done', value: T }>) {
  return map((value: T) => ({ type: 'done', value }));
}
