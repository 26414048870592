import { Component } from '@angular/core';

@Component({
  templateUrl: 'font-awesome.component.html'
})
export class FontAwesomeComponent {

  constructor() { }

}
