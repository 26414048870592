<div class="animated fadeIn">
  <div class="card card-default">
    <div class="card-header">
      <i class="fa fa-picture-o"></i> CoreUI Icons <span class="badge badge-info">New</span>
      <div class="card-header-actions">
        <a href="https://github.com/coreui/coreui-icons" class="card-header-action" target="_blank">Github</a>
      </div>
    </div>
    <div class="card-body">
      <div class="row text-center">
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-account-logout icons font-2xl d-block mt-4"></i>
          <div>account-logout</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-action-redo icons font-2xl d-block mt-4"></i>
          <div>action-redo</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-action-undo icons font-2xl d-block mt-4"></i>
          <div>action-undo</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-align-center icons font-2xl d-block mt-4"></i>
          <div>align-center</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-align-left icons font-2xl d-block mt-4"></i>
          <div>align-left</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-align-right icons font-2xl d-block mt-4"></i>
          <div>align-right</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-arrow-bottom icons font-2xl d-block mt-4"></i>
          <div>arrow-bottom</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-arrow-left icons font-2xl d-block mt-4"></i>
          <div>arrow-left</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-arrow-right icons font-2xl d-block mt-4"></i>
          <div>arrow-right</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-arrow-top icons font-2xl d-block mt-4"></i>
          <div>arrow-top</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-ban icons font-2xl d-block mt-4"></i>
          <div>ban</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-basket-loaded icons font-2xl d-block mt-4"></i>
          <div>basket-loaded</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-bell icons font-2xl d-block mt-4"></i>
          <div>bell</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-bold icons font-2xl d-block mt-4"></i>
          <div>bold</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-bookmark icons font-2xl d-block mt-4"></i>
          <div>bookmark</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-briefcase icons font-2xl d-block mt-4"></i>
          <div>briefcase</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-british-pound icons font-2xl d-block mt-4"></i>
          <div>british-pound</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-brush icons font-2xl d-block mt-4"></i>
          <div>brush</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-calculator icons font-2xl d-block mt-4"></i>
          <div>calculator</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-calendar icons font-2xl d-block mt-4"></i>
          <div>calendar</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-cart icons font-2xl d-block mt-4"></i>
          <div>cart</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-chart icons font-2xl d-block mt-4"></i>
          <div>chart</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-check icons font-2xl d-block mt-4"></i>
          <div>check</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-chevron-bottom icons font-2xl d-block mt-4"></i>
          <div>chevron-bottom</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-chevron-left icons font-2xl d-block mt-4"></i>
          <div>chevron-left</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-chevron-right icons font-2xl d-block mt-4"></i>
          <div>chevron-right</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-chevron-top icons font-2xl d-block mt-4"></i>
          <div>chevron-top</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-circle-check icons font-2xl d-block mt-4"></i>
          <div>circle-check</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-circle-x icons font-2xl d-block mt-4"></i>
          <div>circle-x</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-cloud icons font-2xl d-block mt-4"></i>
          <div>cloud</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-cloud-download icons font-2xl d-block mt-4"></i>
          <div>cloud-download</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-cloud-upload icons font-2xl d-block mt-4"></i>
          <div>cloud-upload</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-code icons font-2xl d-block mt-4"></i>
          <div>code</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-cog icons font-2xl d-block mt-4"></i>
          <div>cog</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-comment-square icons font-2xl d-block mt-4"></i>
          <div>comment-square</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-credit-card icons font-2xl d-block mt-4"></i>
          <div>credit-card</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-cursor icons font-2xl d-block mt-4"></i>
          <div>cursor</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-dashboard icons font-2xl d-block mt-4"></i>
          <div>dashboard</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-delete icons font-2xl d-block mt-4"></i>
          <div>delete</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-dollar icons font-2xl d-block mt-4"></i>
          <div>dollar</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-drop icons font-2xl d-block mt-4"></i>
          <div>drop</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-envelope-closed icons font-2xl d-block mt-4"></i>
          <div>envelope-closed</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-envelope-letter icons font-2xl d-block mt-4"></i>
          <div>envelope-letter</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-envelope-open icons font-2xl d-block mt-4"></i>
          <div>envelope-open</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-euro icons font-2xl d-block mt-4"></i>
          <div>euro</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-file icons font-2xl d-block mt-4"></i>
          <div>file</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-globe icons font-2xl d-block mt-4"></i>
          <div>globe</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-graph icons font-2xl d-block mt-4"></i>
          <div>graph</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-home icons font-2xl d-block mt-4"></i>
          <div>home</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-inbox icons font-2xl d-block mt-4"></i>
          <div>inbox</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-info icons font-2xl d-block mt-4"></i>
          <div>info</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-italic icons font-2xl d-block mt-4"></i>
          <div>italic</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-justify-center icons font-2xl d-block mt-4"></i>
          <div>justify-center</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-justify-left icons font-2xl d-block mt-4"></i>
          <div>justify-left</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-justify-right icons font-2xl d-block mt-4"></i>
          <div>justify-right</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-laptop icons font-2xl d-block mt-4"></i>
          <div>laptop</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-layers icons font-2xl d-block mt-4"></i>
          <div>layers</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-lightbulb icons font-2xl d-block mt-4"></i>
          <div>lightbulb</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-list icons font-2xl d-block mt-4"></i>
          <div>list</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-location-pin icons font-2xl d-block mt-4"></i>
          <div>location-pin</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-lock-locked icons font-2xl d-block mt-4"></i>
          <div>lock-locked</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-lock-unlocked icons font-2xl d-block mt-4"></i>
          <div>lock-unlocked</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-magnifying-glass icons font-2xl d-block mt-4"></i>
          <div>magnifying-glass</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-map icons font-2xl d-block mt-4"></i>
          <div>map</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-monitor icons font-2xl d-block mt-4"></i>
          <div>monitor</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-moon icons font-2xl d-block mt-4"></i>
          <div>moon</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-note icons font-2xl d-block mt-4"></i>
          <div>note</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-options icons font-2xl d-block mt-4"></i>
          <div>options</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-paperclip icons font-2xl d-block mt-4"></i>
          <div>paperclip</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-pencil icons font-2xl d-block mt-4"></i>
          <div>pencil</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-people icons font-2xl d-block mt-4"></i>
          <div>people</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-phone icons font-2xl d-block mt-4"></i>
          <div>phone</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-pie-chart icons font-2xl d-block mt-4"></i>
          <div>pie-chart</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-print icons font-2xl d-block mt-4"></i>
          <div>print</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-puzzle icons font-2xl d-block mt-4"></i>
          <div>puzzle</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-rss icons font-2xl d-block mt-4"></i>
          <div>rss</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-screen-desktop icons font-2xl d-block mt-4"></i>
          <div>screen-desktop</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-screen-smartphone icons font-2xl d-block mt-4"></i>
          <div>screen-smartphone</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-settings icons font-2xl d-block mt-4"></i>
          <div>settings</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-share icons font-2xl d-block mt-4"></i>
          <div>share</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-shield icons font-2xl d-block mt-4"></i>
          <div>shield</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-sort-ascending icons font-2xl d-block mt-4"></i>
          <div>sort-ascending</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-sort-descending icons font-2xl d-block mt-4"></i>
          <div>sort-descending</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-speech icons font-2xl d-block mt-4"></i>
          <div>speech</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-speedometer icons font-2xl d-block mt-4"></i>
          <div>speedometer</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-star icons font-2xl d-block mt-4"></i>
          <div>star</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-sun icons font-2xl d-block mt-4"></i>
          <div>sun</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-tablet icons font-2xl d-block mt-4"></i>
          <div>tablet</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-tags icons font-2xl d-block mt-4"></i>
          <div>tags</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-task icons font-2xl d-block mt-4"></i>
          <div>task</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-thumb-down icons font-2xl d-block mt-4"></i>
          <div>thumb-down</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-thumb-up icons font-2xl d-block mt-4"></i>
          <div>thumb-up</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-trash icons font-2xl d-block mt-4"></i>
          <div>trash</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-underline icons font-2xl d-block mt-4"></i>
          <div>underline</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-user icons font-2xl d-block mt-4"></i>
          <div>user</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-user-female icons font-2xl d-block mt-4"></i>
          <div>user-female</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-user-follow icons font-2xl d-block mt-4"></i>
          <div>user-follow</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-user-unfollow icons font-2xl d-block mt-4"></i>
          <div>user-unfollow</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-wrench icons font-2xl d-block mt-4"></i>
          <div>wrench</div>
        </div>
        <div class="col-6 col-sm-4 col-md-3 col-xl-2">
          <i class="cui-yen icons font-2xl d-block mt-4"></i>
          <div>yen</div>
        </div>
      </div>
      <!--/.row-->
    </div>
  </div>
</div>
