import Cookies from "../../utils/cookies";

export const tour = {
    defaultStepOptions: {
      scrollTo: {behavior: 'smooth', block: 'center'},
      cancelIcon: {
        enabled: true
      },
      canClickTarget: true,
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20]
            }
          }
        ]
      },
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    },
    steps: [
      {
        id: "start",
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            i18n: "dont_show_anymore",
            action() {
              Cookies.set('showed-guide', 'true', { expires: 365 });
              return this.cancel()
            }
          },
          {
            classes: "shepherd-button-primary",
            text: "Start",
            type: "next",
            i18n: "start",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Guide",
        text: "Welcome to Lobbyze! Let's start your tour guide...",
        i18n_text: "tour_start",
        i18n_title: "guide"
      },
      {
        id: "tournaments-page",
        attachTo: {
          element: ".tournaments-page",
          on: "left"
        },
        advanceOn: {
            selector: ".tournaments-page",
            event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tournaments-page')[0] as HTMLElement;
              element.click();
              return this.show('filter-1')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Tournaments",
        text: "First, click here to go to the tournaments page.",
        i18n_text: "tour_tournaments_page",
        i18n_title: "tournaments"
      },
      {
        id: "filter-1",
        attachTo: {
          element: ".main-filters",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "This is where you can input filters in order to narrow down what tournaments you are looking for.",
        i18n_text: "tour_filter_1",
        i18n_title: "filters"
      },
      {
        id: "filter-2",
        attachTo: {
          element: ".tour-filters",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-filters",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tour-filters')[0] as HTMLElement;
              element.click();
              return this.show('filter-3')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Expand Filters",
        text: "Click on this button to show extra filters.",
        i18n_text: "tour_filter_2",
        i18n_title: "expand_filters"
      },
      {
        id: "filter-3",
        attachTo: {
          element: ".extra-filters-body",
          on: "right"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Expand Filters",
        text: "These are all the filters you can enter to find the exact tournaments you're looking for.",
        i18n_text: "tour_filter_3",
        i18n_title: "expand_filters",
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            setTimeout(() => resolve(), 200);
          });
        }
      },
      {
        id: "filter-4",
        attachTo: {
          element: ".tour-see-results",
          on: "right"
        },
        advanceOn: {
            selector: ".tour-see-results",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tour-see-results')[0] as HTMLElement;
              element.click();
              return this.show('filter-5')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Search",
        text: "And then click here to see the results matching tournaments on our database.",
        i18n_text: "tour_filter_4",
        i18n_title: "search"
      },
      {
        id: "filter-5",
        attachTo: {
          element: "list-tournaments",
          on: "top"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "mat-raised-button",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Results",
        text: "All the results are going to be shown in a list format.",
        i18n_text: "tour_filter_5",
        i18n_title: "search"
      },
      {
        id: "schedule-1",
        buttons: [
        {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
        },
        {
            classes: "shepherd-button-primary",
            text: "Start",
            type: "next",
            i18n: "start",
        }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Scheduling",
        text: "Now let's learn how to schedule a tournament.",
        i18n_text: "tour_schedule_1",
        i18n_title: "scheduling"
      },
      {
        id: "schedule-2",
        attachTo: {
          element: ".tour-checkbox",
          on: "top"
        },
        advanceOn: {
            selector: ".tour-checkbox",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tour-checkbox')[0] as HTMLElement;
              element.click();
              return this.show('schedule-3')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Scheduling",
        text: "First, select a tournament to be scheduled.",
        i18n_text: "tour_schedule_2",
        i18n_title: "scheduling"
      },
      {
        id: "schedule-3",
        attachTo: {
          element: ".tour-schedule-button",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-schedule-button",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tour-schedule-button')[0] as HTMLElement;
              element.click();
              return this.show('schedule-32')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Scheduling",
        text: "Then, click on Schedule to add the selected tournaments to your schedule.",
        i18n_text: "tour_schedule_3",
        i18n_title: "scheduling",
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            setTimeout(() => resolve(), 200);
          });
        }
      },
      {
        id: "schedule-32",
        attachTo: {
          element: ".tour-schedule-modal",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-schedule-modal-button",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tour-schedule-modal-button')[0] as HTMLElement;
              element.click();
              return this.show('schedule-4')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Scheduling",
        text: "Then select for which days you want to schedule the selected tournaments and click add.",
        i18n_text: "tour_schedule_32",
        i18n_title: "scheduling",
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            setTimeout(() => resolve(), 500);
          });
        }
      },
      {
        id: "schedule-4",
        attachTo: {
          element: ".tour-schedule-success",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-schedule-success",
            event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let modal: HTMLElement = document.getElementsByClassName('tour-close-modal')[0] as HTMLElement;
              modal.click();
              return this.show('grind-1')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Scheduling",
        text: "The tournaments were successfully added to your schedule.",
        i18n_text: "tour_schedule_4",
        i18n_title: "scheduling",
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            var checkExist = setInterval(function() {
              let element: HTMLElement = document.getElementsByClassName('tour-schedule-success')[0] as HTMLElement;
              if (element) {
                clearInterval(checkExist);
                resolve();
              }
            }, 100);
          });
        }
      },
      {
        id: "grind-1",
        attachTo: {
          element: ".tour-grind",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-grind",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            type: "next"
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Grind Mode",
        text: "If you wish to check your daily tournaments, click on the Grind Mode button.",
        i18n_text: "tour_grind_1",
        i18n_title: "grind_mode"
      },
      {
        id: "grind-2",
        attachTo: {
          element: ".tour-saved-filters",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-saved-filters",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            type: "next"
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Grind Mode",
        text: "If you wish to check your daily tournaments, click on the Grind Mode button.",
        i18n_text: "tour_grind_2",
        i18n_title: "saved_filters"
      },
      {
        id: "grind-3",
        attachTo: {
          element: ".tour-auto-schedule",
          on: "bottom"
        },
        advanceOn: {
            selector: ".tour-auto-schedule",
            event: "click"
        },
        buttons: [
          {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            type: "next"
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Grind Mode",
        text: "If you wish to check your daily tournaments, click on the Grind Mode button.",
        i18n_text: "tour_grind_3",
        i18n_title: "auto_schedule"
      },
      {
        id: "finish",
        buttons: [
        {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
        },
        {
            classes: "shepherd-button-primary",
            text: "Finish",
            i18n: "close",
            action() {
              Cookies.set('showed-guide', 'true', { expires: 365 });
              return this.complete()
            }
        }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Congratulations",
        text: "And that's it! You learned the basic functions of Lobbyze and are ready to start!",
        i18n_text: "tour_finish",
        i18n_title: "congratulations"
      },
    ]
  };

  export const tourRebalance = {
    defaultStepOptions: {
      scrollTo: {behavior: 'smooth', block: 'center'},
      cancelIcon: {
        enabled: true
      },
      canClickTarget: true,
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20]
            }
          }
        ]
      },
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    },
    steps: [
      {
        id: "start",
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Start",
            type: "next",
            i18n: "start",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Guide",
        text: "Let's guide you trought the main functions of rebalance",
        i18n_text: "rebalance_tour_1",
        i18n_title: "guide"
      },
      {
        id: "rebalancec-page",
        attachTo: {
          element: ".rebalance-page",
          on: "left"
        },
        advanceOn: {
            selector: ".rebalance-page",
            event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('rebalance-page')[0] as HTMLElement;
              element.click();
              return this.show('rebalance-1')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Tournaments",
        text: "First, click here to go to the rebalance page.",
        i18n_text: "tour_rebalance_page",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-1",
        attachTo: {
          element: ".guide-list",
          on: "top"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "When a tournament no longer matches the filters you have created it will be listed here.",
        i18n_text: "rebalance_tour_2",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-2",
        attachTo: {
          element: ".guide-top",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Here you can see the number of tournaments that don't match your filters and the option to turn on auto rebalance.",
        i18n_text: "rebalance_tour_3",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-3",
        attachTo: {
          element: ".guide-switch",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "If you turn on the auto rebalance function, your tournaments are going to be automatically removed from your schedules when they don't match the original filter anymore.",
        i18n_text: "rebalance_tour_4",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-4",
        attachTo: {
          element: ".guide-tournament",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Let's use this tournament as an example. When there are tournament changes, the first line shows the current version while the bottom line was the previous version of this tournament.",
        i18n_text: "rebalance_tour_5",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-5",
        attachTo: {
          element: ".text-warning",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Values that don't match the filter are highlighted in yellow",
        i18n_text: "rebalance_tour_6",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-6",
        attachTo: {
          element: ".guide-filter-name",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Here you can see the filter that scheduled this tournament",
        i18n_text: "rebalance_tour_7",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-7",
        attachTo: {
          element: ".guide-cause",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "And here is the cause that made this tournament get flagged for rebalance",
        i18n_text: "rebalance_tour_8",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-8",
        attachTo: {
          element: ".guide-ignore",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "You can choose to ignore the rebalance and keep this tournament on your schedule",
        i18n_text: "rebalance_tour_9",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "rebalance-9",
        attachTo: {
          element: ".guide-remove",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Or you can remove this tournament from your schedule",
        i18n_text: "rebalance_tour_10",
        i18n_title: "rebalance_tournaments"
      },
      {
        id: "finish",
        buttons: [
        {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
        },
        {
            classes: "shepherd-button-primary",
            text: "Finish",
            i18n: "close",
            action() {
              return this.complete()
            }
        }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Congratulations",
        text: "And that's all you need to know to start managing your saved filters!",
        i18n_text: "rebalance_tour_11",
        i18n_title: "congratulations"
      },
    ]
  };

  export const tourManageFilters = {
    defaultStepOptions: {
      scrollTo: {behavior: 'smooth', block: 'center'},
      cancelIcon: {
        enabled: true
      },
      canClickTarget: true,
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20]
            }
          }
        ]
      },
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    },
    steps: [
      {
        id: "start",
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Start",
            type: "next",
            i18n: "start",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Guide",
        text: "Let's guide you trought the main functions of manage filters",
        i18n_text: "manage_filters_tour_1",
        i18n_title: "guide"
      },
      {
        id: "manage-filters-page",
        attachTo: {
          element: ".manage-filters-page",
          on: "left"
        },
        advanceOn: {
            selector: ".manage-filters-page",
            event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('manage-filters-page')[0] as HTMLElement;
              element.click();
              return this.show('manage-filters-0')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Tournaments",
        text: "First, click here to go to the manage filters page.",
        i18n_text: "tour_manage_filters_page",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-0",
        attachTo: {
          element: ".tour-list-tiers",
          on: "top"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Tournaments",
        text: "This is a list of your filters separated by tier",
        i18n_text: "manage_filters_tour_2",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-1",
        attachTo: {
          element: ".tier-checkbox",
          on: "top"
        },
        advanceOn: {
          selector: ".tier-checkbox",
          event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tier-checkbox')[0] as HTMLElement;
              let tierList: HTMLElement = document.getElementsByClassName('tour-tier-open')[0] as HTMLElement;
              if(!tierList) {
                element.click();
              }
              return this.show('manage-filters-2')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Click to open your tier",
        i18n_text: "manage_filters_tour_3",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-2",
        attachTo: {
          element: ".filter-checkbox",
          on: "right"
        },
        advanceOn: {
          selector: ".filter-checkbox",
          event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('filter-checkbox')[0] as HTMLElement;
              element.click();
              return this.show('manage-filters-3')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Then select the filters you want to edit",
        i18n_text: "manage_filters_tour_4",
        i18n_title: "manage_filters",
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            setTimeout(() => resolve(), 200);
          });
        }
      },
      {
        id: "manage-filters-3",
        attachTo: {
          element: ".tour-edit-btn",
          on: "top"
        },
        advanceOn: {
          selector: ".tour-edit-btn",
          event: "click"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            i18n: "next",
            action() {
              let element: HTMLElement = document.getElementsByClassName('tour-edit-btn')[0] as HTMLElement;
              element.click();
              return this.show('manage-filters-4')
            }
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Click here to go to the edit page",
        i18n_text: "manage_filters_tour_5",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-4",
        attachTo: {
          element: ".tour-filter-changes",
          on: "top"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Here you can select the modifications you want to apply to the selected filters",
        i18n_text: "manage_filters_tour_6",
        i18n_title: "manage_filters",
        beforeShowPromise: () => {
          return new Promise<void>(resolve => {
            setTimeout(() => resolve(), 500);
          });
        }
      },
      {
        id: "manage-filters-5",
        attachTo: {
          element: ".tour-action",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "First you select an action, which can be a addition/modification, or a deletion",
        i18n_text: "manage_filters_tour_7",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-6",
        attachTo: {
          element: ".tour-field",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "Then you select the field you want to apply the change",
        i18n_text: "manage_filters_tour_8",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-7",
        attachTo: {
          element: ".tour-value",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "And finally how you want to modify this field",
        i18n_text: "manage_filters_tour_9",
        i18n_title: "manage_filters"
      },
      {
        id: "manage-filters-8",
        attachTo: {
          element: ".tour-preview",
          on: "top"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "A preview of the changes are going to be shown here",
        i18n_text: "manage_filters_tour_10",
        i18n_title: "manage_filters"
      },
      {
        id: "rebalance-9",
        attachTo: {
          element: ".tour-save-btn",
          on: "bottom"
        },
        buttons: [
          {
            classes: "shepherd-button-primary",
            text: "Back",
            type: "back",
            i18n: "back",
          },
          {
            classes: "shepherd-button-primary",
            text: "Next",
            type: "next",
            i18n: "next",
          }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Filters",
        text: "After you make sure everything is correct you can click save to apply these changes",
        i18n_text: "manage_filters_tour_11",
        i18n_title: "manage_filters"
      },
      {
        id: "finish",
        buttons: [
        {
            classes: "mat-raised-button",
            text: "Back",
            type: "back",
            i18n: "back",
        },
        {
            classes: "shepherd-button-primary",
            text: "Finish",
            i18n: "close",
            action() {
              return this.complete()
            }
        }
        ],
        classes: "",
        highlightClass: "highlight",
        title: "Congratulations",
        text: "And that's all you need to know to start managing your saved filters!",
        i18n_text: "manage_filters_tour_12",
        i18n_title: "congratulations"
      },
    ]
  };