<div class="d-flex h-100 align-items-center justify-content-center">
    <div class="row">
        <div class="card col-12">
            <div class="card-body p-5">
                <div class="clearfix">
                    <h4 class="pt-3">Oops! You don't have access!</h4>
                    <p class="text-muted">Contact the owner of your tier.</p>
                </div>
            </div>
        </div>
    </div>
</div>