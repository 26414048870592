import { Injectable } from '@angular/core';
import { environment } from '../../environments'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'


@Injectable()
export class MessagingService {

baseURL = environment.API_URL + "/"
 
constructor(
  private http: HttpClient
) {
 
  //    this.angularFireMessaging.messaging.subscribe(
  //    (msgings) => {
  //  msgings.onMessage = msgings.onMessage.bind(msgings);
  //  msgings.onTokenRefresh=msgings.onTokenRefresh.bind(msgings);
  //   })
  }
 
    sendNotification(title: string, body: string, token: string): Observable<any> {
      
    const notification = {
      "notification": {
        "body": body,
        "title": title,
        "image": "https://pokertools.ml/assets/img/brand/logo_rectangulo.png"
        },
        "token": token
    }
    console.info(this.baseURL + "send-push")

      return this.http.post<any>(this.baseURL + "send-push", notification)
      
  }
}

