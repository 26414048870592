import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { WithLoadingPipe, WithMultipleLoadingPipe } from "./withLoading";

@NgModule({
    declarations: [WithLoadingPipe, WithMultipleLoadingPipe],
    exports: [WithLoadingPipe, WithMultipleLoadingPipe],
    imports: [
        CommonModule,
    ]
})
export class WithLoadingModule {}