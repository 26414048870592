import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObserveOnSubscriber } from 'rxjs/internal/operators/observeOn';
import { environment } from '../../environments';
import { Ad } from '../interfaces/ads';

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  baseURL = environment.API_URL + 'v2/ad/'

  constructor(private http: HttpClient) { }

  setTags(adId:number, body: any): Observable<any> {
    return this.http.put(this.baseURL + 'tags/' + adId, { tags: body})
  }

  deleteMedia(tagID: number): Observable<Ad> {
    return this.http.delete<Ad>(this.baseURL + 'delete-media/' + tagID)
  }

  uploadMedia(file: File, body: {
    status: boolean;
    title: string;
    url: string;
    show_until: string;
  }): Observable<any> {
    const formData = new FormData();
    formData.append('status', body.status.toString())
    formData.append('title', body.title)
    formData.append('url', body.url)
    formData.append('show_until', body.show_until)
    formData.append('file', file);
    return this.http.post(this.baseURL + 'upload-media', formData, {
      responseType: 'json'
    });
  }

  updateMedia(idAd: number, file: File, body: {
    status: boolean;
    title: string;
    url: string;
    show_until: string;
  }): Observable<any> {
    return this.http.put(this.baseURL + idAd, body, {
      responseType: 'json'
    });
  }

  getMedia(): Observable<any> {
    return this.http.get(this.baseURL + 'list', {
      params: {
        page: '0',
      },
      responseType: 'json'
    });
  }

  getRandomMedia(tagOrId: string): Observable<any> {
    return this.http.get(this.baseURL + tagOrId, {
      responseType: 'blob' 
    })
  }

}
