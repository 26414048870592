import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments";
import { SearchTierResponse, Tier, TierLicense } from "../interfaces/v2/tier";

@Injectable({
  providedIn: "root",
})
export class TierService {
  baseURL = environment.API_URL + "v2/tier/";
  baseURLMisc = environment.API_URL + "v2/misc/";

  constructor(private http: HttpClient) {}

  searchTiers(name: string, page: number = 0, order?:Map<string,string>) {
    let params = {
      page: page.toString(),
      name
    }

    if(order){
      let mappedSortValues = new URLSearchParams()
      order.forEach((value:string, key:string) =>{
       mappedSortValues.append(key,value)
      })

      params['order'] = mappedSortValues.toString().replace(/[=]/g,':').replace(/[&]/g,',')
    }
    return this.http.get<SearchTierResponse>(this.baseURL + "search", { params });
  }

  getTier(id: number, admin: boolean = false) {
    const params = {}
    if (admin) {
      params['admin'] = '1'
    }
    return this.http.get<Tier>(this.baseURL + "get/" + id, {
      params,
    });
  }

  deleteTier(id: number, admin: boolean = false): Observable<boolean> {
    const params = {}
    if (admin) {
      params['admin'] = '1'
    }
    return this.http.delete(this.baseURL + "delete/" + id, {
      observe: 'response',
      params,
    }).pipe(
      map(res => res.status === 200)
    );
  }

  setUser(userId: number, tierId: number, master: boolean | null = null, admin: boolean = false): Observable<boolean> {
    const params = {}
    if (admin) {
      params['admin'] = '1'
    }
    return this.http.post(this.baseURL + "user/add", {
      user: userId,
      tier: tierId,
      master,
    }, {
      observe: 'response',
      params,
    }).pipe(
      map(res => res.status === 200)
    );
  }

  setUserByEmail(userEmail: string, master: boolean | null = null): Observable<boolean> {
    return this.http.post(this.baseURL + "user/add", {
      email: userEmail,
      master,
    },
    {
      observe: "response",
    }).pipe(map((res) => res.status === 200));
  }

  removeUser(userId: number, tierId: number, admin: boolean = false): Observable<boolean> {
    const params = {}
    if (admin) {
      params['admin'] = '1'
    }
    return this.http.post(this.baseURL + "user/remove", {
      user: userId,
      tier: tierId,
    }, {
      observe: 'response',
      params,
    }).pipe(
      map(res => res.status === 200)
    );
  }

  createTier(name: string, ownerID: number = 0, admin: boolean = false) {
    const params = {}
    if (admin) {
      params['admin'] = '1'
    }
    return this.http.post(this.baseURL + "create", {
      name,
      owner: ownerID,
    }, {
      params,
     });
  }

  updateTier(id: number, body: {
    name?: string,
    enable_audit?: boolean,
  }, admin: boolean = true): Observable<boolean> {
    const params = {}
    if (admin) {
      params['admin'] = '1'
    }
    return this.http.put(this.baseURL + "update/" + id, body, {
      observe: 'response',
      params,
    }).pipe(
      map(res => res.status ===200)
    )
  }

  getTierLicense(tierId: number = 0, admin: boolean = false) {
    const params = {}
    if (admin) {
      params['tier'] = tierId.toString()
    }
    return this.http.get<TierLicense>(this.baseURL + "get-license", {
      params,
    })
  }

  copyTier(sourceId: number, targetId: string, copyType:string = "MERGE") {
    return this.http.post(this.baseURL + "copy-schedules", {
        source_tier: sourceId,
        target_tier: targetId,
        copy_type: copyType
    })
  }

  getRequest(master: boolean) {
    return this.http.get(this.baseURL + "request", {
      params: {
        master_query: master.toString()
      }
    })
  }

  putRequest(ids: Array<number>): Observable<boolean> {
    return this.http.put(this.baseURL + "request", {
      tournaments: ids
    }, {
      observe: 'response',
    }).pipe(
      map(res => res.status ===200)
    )
  }

  manageRequest(tournaments: number[], user: number, accept: boolean, tier: number, replicate: boolean, all_members: boolean): Observable<boolean> {
    return this.http.put(this.baseURL + "request/manage", {
      tournaments, user, accept, tier, replicate, all_members
    }, {
      observe: "response",
    }).pipe(
      map(res => res.status ===200)
    )
  }

  listRequest(): Observable<any> {
    return this.http.get(this.baseURL + "request/list")
  }

  getLogs(body): Observable<any> {
    return this.http.post<any>(this.baseURL + "log", body)
  }

  getTierInfo(): Observable<any> {
    return this.http.get<any>(this.baseURL + "info")
  }

  putTierInfo(body): Observable<any> {
    return this.http.put<any>(this.baseURL + "info", body)
  }

  getRedFlag(): Observable<any> {
    return this.http.get<any>(this.baseURL + "flag")
  }

  putTierFlag(body): Observable<any> {
    return this.http.put<any>(this.baseURL + "flag", body)
  }

  getRebalanceSettings(): Observable<any> {
    return this.http.get<any>(this.baseURL + "as-settings")
  }

  saveRebalanceSettings(body): Observable<any> {
    return this.http.put<any>(this.baseURL + "as-settings", body)
  }

  executeAutoScheduler(): Observable<any> {
    return this.http.post<any>(this.baseURLMisc + "auto_schedule/tier", {},
      {
        observe: "response",
      }).pipe(
        map(res => res.status ===200)
      )
  }

}
