import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, share } from "rxjs/operators";
import { environment } from "../../environments";
import { Filter, User } from "../interfaces/user";
import { Tier } from "../interfaces/v2/tier";
import { ListUserResponse, Profile, UserInfoData } from "../interfaces/v2/user";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  baseURLv2 = environment.API_URL + "v2/user/";
  baseURLmisc =  environment.API_URL + "v2/misc/"

  private static lastRequestTime = 0;
  private static lastRequest: Observable<User>;

  private lastUser: User | null = null;

  constructor(private http: HttpClient, private authService: AuthService) {}

  getCurrentUser(): Observable<User> {
    const now = Date.now();
    if (now - UserService.lastRequestTime < 9000) {
      return UserService.lastRequest;
    }
    UserService.lastRequestTime = now;
    const req = this.http.get<User>(this.baseURLv2 + "full-data").pipe(
      map((user) => {
        this.lastUser = user;
        return user;
      }),
      share(),
    );
    UserService.lastRequest = req;
    return req;
  }

  getPendingRequest(): Observable<boolean> {
    return this.http.get<string>(this.baseURLv2 + "requests").pipe(
      map(e => {
        return e.toString() == "true"
      })
    )
  }

  getRole() {
    return this.http.get<{ sa: boolean; ta: boolean; master: boolean }>(
      this.baseURLv2 + "role"
    );
  }

  async getLastUser(): Promise<User> {
    if (this.lastUser != null) {
      return this.lastUser;
    }
    const user = await this.getCurrentUser().toPromise();
    return user;
  }

  searchUsers(name: string, page: number = 0) {
    return this.http.get<ListUserResponse>(this.baseURLv2 + "search", {
      params: {
        name,
        page: page.toString(),
      },
    });
  }

  getFilters() {
    return this.http.get<{
      deleted: boolean, id: number, data: Filter, auto_schedule: boolean, priority: number, user_id: number, user_name: string
    }[]>(
      this.baseURLv2 + "filters"
    );
  }

  getFiltersFromTier(get_tier: boolean = false, all_tiers: boolean = false) {
    return this.http.post<{
      deleted: boolean, id: number, data: Filter, auto_schedule: boolean, priority: number, user_id: number, user_name: string, tier_id: number
    }[]>(
      this.baseURLv2 + "filters", {get_tier, all_tiers}
    );
  }

  copyFilters(filters: Array<number>, tiers: Array<number>) {
    return this.http.post<{ id: number }>(
      this.baseURLv2 + "filters/copy-filters",
      {
        filters,
        tiers
      }
    );
  }

  updateUserFilter(
    filterID: number,
    filter: {
      data: object,
      auto_schedule: boolean,
      priority: number
    }
  ): Observable<{ id: number }> {
    return this.http.put<{ id: number }>(
      this.baseURLv2 + "update-user-filter/" + (filterID || 0),
      filter
    );
  }

  deleteUserFilter(filterID: number): Observable<Filter> {
    return this.http.delete<Filter>(
      this.baseURLv2 + "delete-user-filter/" + filterID
    );
  }

  deleteUser(userId: number): Observable<boolean> {
    return this.http
      .delete(this.baseURLv2 + userId, { observe: "response" })
      .pipe(
        map((res) => {
          return res.status === 200;
        })
      );
  }

  getUserData(id: number): Observable<UserInfoData> {
    return this.http.get<UserInfoData>(this.baseURLv2 + id);
  }

  updateUserData(
    id: number,
    body: {
      name: string;
      email: string;
      login: string;
      password: string;
      verified_email: boolean;
      is_ta: boolean;
      is_sa: boolean;
    }
  ): Observable<{ ok: boolean; msg?: string }> {
    return this.http
      .post(this.baseURLv2 + id, body, {
        observe: "response",
      })
      .pipe(
        map((e) => {
          if (e.status == 200) {
            return { ok: true };
          } else {
            return { ok: false, msg: e.body["error"] };
          }
        })
      );
  }

  listTiers(): Observable<Tier[]> {
    return this.http.get<Tier[]>(this.baseURLv2 + "tiers", {
      params: {
        master: '1',
      },
    });
  }

  getProfile(ids: Array<number>, isAdmin: boolean = false, includeSites = false) {
    const params = {
      user_ids: ids ? ids.toString() : "",
      admin: isAdmin.toString(),
    };
    if (includeSites) {
      params["include_sites"] = 1;
    }
    return this.http.get<object>(this.baseURLv2 + "profile", {
      params,
    });
  }

  putProfile(profile: Profile, id: number) {
    return this.http.put(this.baseURLv2 + "profile", profile, {
      params: {
        user_id: id ? id.toString() : "",
      },
    })
  }

  sendValidation(): Observable<{ ok: boolean; msg?: string }> {
    return this.http.post(this.baseURLv2 + "send_validation", {} , {
      observe: "response",
    })
    .pipe(
      map((e) => {
        if(e.status === 200) {
          return { ok: true };
        } else {
          return { ok: false, msg: e.body["error"] }
        }
      })
    )
  }

  getUsers(body):Observable<any> {
    return this.http.post<any>(this.baseURLmisc + "search-users", body)
  }

  getCustomSites(id: number): Observable<any> {
    return this.http.get<any>(this.baseURLv2 + "custom-site/" + id)
  }

  setCustomSites(id:number, sites: Array<number>): Observable<any> {
    return this.http.put<any>(this.baseURLv2 + "custom-site", {
      id: id,
      enabled_sites: sites
    })
  }
}
