import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SwPush } from "@angular/service-worker";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments";
import { Notification } from "../interfaces/notification";
import { NotificationConfig } from "../interfaces/v2/notification_config";
import { ScheduleService } from "./schedule.service";
import { Schedule } from "../interfaces/schedule";

export interface RegisterStatus {
  enabled: boolean
  err?: any
}

@Injectable({ providedIn: "root" })
export class NotificationService {
  private readonly baseURL = environment.API_URL + "notifications";
  private readonly baseURLv2 = environment.API_URL + "v2/notification";

  private registerStatus = new BehaviorSubject<RegisterStatus>({ enabled: false });

  constructor(
    private http: HttpClient,
  ) {}

  get() {
    return this.http.get<Notification[]>(this.baseURL + "/");
  }

  view(ids: number[]) {
    return this.http.post(this.baseURL + "/view", { ids });
  }

  dismiss(ids: number[]) {
    return this.http.post(this.baseURL + "/dismiss", { ids });
  }

  // before here, v1 api

  getScheduleNotification(schedules: number[]): Observable<any[]> {
    return this.http.post<any>(this.baseURLv2 + "/schedule", {
        schedules
      }
    );
  }

  updateScheduleNotification(notifications: Array<object>, ignore_warnings: boolean = true) {
    return this.http.put(this.baseURLv2 + "/schedule", {
      notifications,
      ignore_warnings
    });
  }

  subscribe(subscription: PushSubscription) {
    return this.http.post(this.baseURLv2 + "/subscribe", {
      token: JSON.stringify(subscription),
    }, {
      observe: 'response'
    });
  }

  getRegistrationStatus() {
    return this.registerStatus
  }

  getUpcoming() {
    return this.http.get<Schedule[]>(this.baseURLv2 + "/upcoming");
  }


  pingNotification() {
    return this.http.post<any>(this.baseURLv2 + "/ping", null);
  }

  openLobby(tournament: number) {
    return this.http.post(this.baseURLv2 + "/open", {
      tournament
    }).toPromise()
  }
}
