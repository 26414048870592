<div class="animated fadeIn">
  <div class="card card-default">
    <div class="card-header">
      <i class="fa fa-picture-o"></i> Simple Line Icons
    </div>
    <div class="card-body">
      <div class="row text-center">
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-user icons font-2xl d-block mt-4"></i>icon-user
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-people icons font-2xl d-block mt-4"></i>icon-people
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-user-female icons font-2xl d-block mt-4"></i>icon-user-female
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-user-follow icons font-2xl d-block mt-4"></i>icon-user-follow
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-user-following icons font-2xl d-block mt-4"></i>icon-user-following
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-user-unfollow icons font-2xl d-block mt-4"></i>icon-user-unfollow
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-login icons font-2xl d-block mt-4"></i>icon-login
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-logout icons font-2xl d-block mt-4"></i>icon-logout
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-emotsmile icons font-2xl d-block mt-4"></i>icon-emotsmile
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-phone icons font-2xl d-block mt-4"></i>icon-phone
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-call-end icons font-2xl d-block mt-4"></i>icon-call-end
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-call-in icons font-2xl d-block mt-4"></i>icon-call-in
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-call-out icons font-2xl d-block mt-4"></i>icon-call-out
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-map icons font-2xl d-block mt-4"></i>icon-map
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-location-pin icons font-2xl d-block mt-4"></i>icon-location-pin
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-direction icons font-2xl d-block mt-4"></i>icon-direction
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-directions icons font-2xl d-block mt-4"></i>icon-directions
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-compass icons font-2xl d-block mt-4"></i>icon-compass
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-layers icons font-2xl d-block mt-4"></i>icon-layers
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-menu icons font-2xl d-block mt-4"></i>icon-menu
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-list icons font-2xl d-block mt-4"></i>icon-list
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-options-vertical icons font-2xl d-block mt-4"></i>icon-options-vertical
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-options icons font-2xl d-block mt-4"></i>icon-options
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-down icons font-2xl d-block mt-4"></i>icon-arrow-down
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-left icons font-2xl d-block mt-4"></i>icon-arrow-left
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-right icons font-2xl d-block mt-4"></i>icon-arrow-right
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-up icons font-2xl d-block mt-4"></i>icon-arrow-up
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-up-circle icons font-2xl d-block mt-4"></i>icon-arrow-up-circle
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-left-circle icons font-2xl d-block mt-4"></i>icon-arrow-left-circle
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-right-circle icons font-2xl d-block mt-4"></i>icon-arrow-right-circle
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-arrow-down-circle icons font-2xl d-block mt-4"></i>icon-arrow-down-circle
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-check icons font-2xl d-block mt-4"></i>icon-check
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-clock icons font-2xl d-block mt-4"></i>icon-clock
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-plus icons font-2xl d-block mt-4"></i>icon-plus
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-close icons font-2xl d-block mt-4"></i>icon-close
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-trophy icons font-2xl d-block mt-4"></i>icon-trophy
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-screen-smartphone icons font-2xl d-block mt-4"></i>icon-screen-smartphone
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-screen-desktop icons font-2xl d-block mt-4"></i>icon-screen-desktop
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-plane icons font-2xl d-block mt-4"></i>icon-plane
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-notebook icons font-2xl d-block mt-4"></i>icon-notebook
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-mustache icons font-2xl d-block mt-4"></i>icon-mustache
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-mouse icons font-2xl d-block mt-4"></i>icon-mouse
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-magnet icons font-2xl d-block mt-4"></i>icon-magnet
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-energy icons font-2xl d-block mt-4"></i>icon-energy
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-disc icons font-2xl d-block mt-4"></i>icon-disc
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-cursor icons font-2xl d-block mt-4"></i>icon-cursor
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-cursor-move icons font-2xl d-block mt-4"></i>icon-cursor-move
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-crop icons font-2xl d-block mt-4"></i>icon-crop
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-chemistry icons font-2xl d-block mt-4"></i>icon-chemistry
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-speedometer icons font-2xl d-block mt-4"></i>icon-speedometer
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-shield icons font-2xl d-block mt-4"></i>icon-shield
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-screen-tablet icons font-2xl d-block mt-4"></i>icon-screen-tablet
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-magic-wand icons font-2xl d-block mt-4"></i>icon-magic-wand
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-hourglass icons font-2xl d-block mt-4"></i>icon-hourglass
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-graduation icons font-2xl d-block mt-4"></i>icon-graduation
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-ghost icons font-2xl d-block mt-4"></i>icon-ghost
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-game-controller icons font-2xl d-block mt-4"></i>icon-game-controller
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-fire icons font-2xl d-block mt-4"></i>icon-fire
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-eyeglass icons font-2xl d-block mt-4"></i>icon-eyeglass
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-envelope-open icons font-2xl d-block mt-4"></i>icon-envelope-open
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-envelope-letter icons font-2xl d-block mt-4"></i>icon-envelope-letter
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-bell icons font-2xl d-block mt-4"></i>icon-bell
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-badge icons font-2xl d-block mt-4"></i>icon-badge
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-anchor icons font-2xl d-block mt-4"></i>icon-anchor
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-wallet icons font-2xl d-block mt-4"></i>icon-wallet
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-vector icons font-2xl d-block mt-4"></i>icon-vector
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-speech icons font-2xl d-block mt-4"></i>icon-speech
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-puzzle icons font-2xl d-block mt-4"></i>icon-puzzle
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-printer icons font-2xl d-block mt-4"></i>icon-printer
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-present icons font-2xl d-block mt-4"></i>icon-present
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-playlist icons font-2xl d-block mt-4"></i>icon-playlist
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-pin icons font-2xl d-block mt-4"></i>icon-pin
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-picture icons font-2xl d-block mt-4"></i>icon-picture
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-handbag icons font-2xl d-block mt-4"></i>icon-handbag
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-globe-alt icons font-2xl d-block mt-4"></i>icon-globe-alt
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-globe icons font-2xl d-block mt-4"></i>icon-globe
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-folder-alt icons font-2xl d-block mt-4"></i>icon-folder-alt
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-folder icons font-2xl d-block mt-4"></i>icon-folder
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-film icons font-2xl d-block mt-4"></i>icon-film
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-feed icons font-2xl d-block mt-4"></i>icon-feed
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-drop icons font-2xl d-block mt-4"></i>icon-drop
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-drawer icons font-2xl d-block mt-4"></i>icon-drawer
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-docs icons font-2xl d-block mt-4"></i>icon-docs
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-doc icons font-2xl d-block mt-4"></i>icon-doc
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-diamond icons font-2xl d-block mt-4"></i>icon-diamond
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-cup icons font-2xl d-block mt-4"></i>icon-cup
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-calculator icons font-2xl d-block mt-4"></i>icon-calculator
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-bubbles icons font-2xl d-block mt-4"></i>icon-bubbles
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-briefcase icons font-2xl d-block mt-4"></i>icon-briefcase
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-book-open icons font-2xl d-block mt-4"></i>icon-book-open
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-basket-loaded icons font-2xl d-block mt-4"></i>icon-basket-loaded
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-basket icons font-2xl d-block mt-4"></i>icon-basket
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-bag icons font-2xl d-block mt-4"></i>icon-bag
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-action-undo icons font-2xl d-block mt-4"></i>icon-action-undo
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-action-redo icons font-2xl d-block mt-4"></i>icon-action-redo
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-wrench icons font-2xl d-block mt-4"></i>icon-wrench
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-umbrella icons font-2xl d-block mt-4"></i>icon-umbrella
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-trash icons font-2xl d-block mt-4"></i>icon-trash
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-tag icons font-2xl d-block mt-4"></i>icon-tag
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-support icons font-2xl d-block mt-4"></i>icon-support
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-frame icons font-2xl d-block mt-4"></i>icon-frame
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-size-fullscreen icons font-2xl d-block mt-4"></i>icon-size-fullscreen
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-size-actual icons font-2xl d-block mt-4"></i>icon-size-actual
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-shuffle icons font-2xl d-block mt-4"></i>icon-shuffle
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-share-alt icons font-2xl d-block mt-4"></i>icon-share-alt
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-share icons font-2xl d-block mt-4"></i>icon-share
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-rocket icons font-2xl d-block mt-4"></i>icon-rocket
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-question icons font-2xl d-block mt-4"></i>icon-question
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-pie-chart icons font-2xl d-block mt-4"></i>icon-pie-chart
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-pencil icons font-2xl d-block mt-4"></i>icon-pencil
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-note icons font-2xl d-block mt-4"></i>icon-note
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-loop icons font-2xl d-block mt-4"></i>icon-loop
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-home icons font-2xl d-block mt-4"></i>icon-home
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-grid icons font-2xl d-block mt-4"></i>icon-grid
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-graph icons font-2xl d-block mt-4"></i>icon-graph
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-microphone icons font-2xl d-block mt-4"></i>icon-microphone
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-music-tone-alt icons font-2xl d-block mt-4"></i>icon-music-tone-alt
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-music-tone icons font-2xl d-block mt-4"></i>icon-music-tone
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-earphones-alt icons font-2xl d-block mt-4"></i>icon-earphones-alt
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-earphones icons font-2xl d-block mt-4"></i>icon-earphones
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-equalizer icons font-2xl d-block mt-4"></i>icon-equalizer
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-like icons font-2xl d-block mt-4"></i>icon-like
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-dislike icons font-2xl d-block mt-4"></i>icon-dislike
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-control-start icons font-2xl d-block mt-4"></i>icon-control-start
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-control-rewind icons font-2xl d-block mt-4"></i>icon-control-rewind
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-control-play icons font-2xl d-block mt-4"></i>icon-control-play
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-control-pause icons font-2xl d-block mt-4"></i>icon-control-pause
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-control-forward icons font-2xl d-block mt-4"></i>icon-control-forward
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-control-end icons font-2xl d-block mt-4"></i>icon-control-end
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-volume-1 icons font-2xl d-block mt-4"></i>icon-volume-1
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-volume-2 icons font-2xl d-block mt-4"></i>icon-volume-2
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-volume-off icons font-2xl d-block mt-4"></i>icon-volume-off
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-calendar icons font-2xl d-block mt-4"></i>icon-calendar
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-bulb icons font-2xl d-block mt-4"></i>icon-bulb
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-chart icons font-2xl d-block mt-4"></i>icon-chart
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-ban icons font-2xl d-block mt-4"></i>icon-ban
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-bubble icons font-2xl d-block mt-4"></i>icon-bubble
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-camrecorder icons font-2xl d-block mt-4"></i>icon-camrecorder
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-camera icons font-2xl d-block mt-4"></i>icon-camera
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-cloud-download icons font-2xl d-block mt-4"></i>icon-cloud-download
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-cloud-upload icons font-2xl d-block mt-4"></i>icon-cloud-upload
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-envelope icons font-2xl d-block mt-4"></i>icon-envelope
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-eye icons font-2xl d-block mt-4"></i>icon-eye
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-flag icons font-2xl d-block mt-4"></i>icon-flag
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-heart icons font-2xl d-block mt-4"></i>icon-heart
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-info icons font-2xl d-block mt-4"></i>icon-info
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-key icons font-2xl d-block mt-4"></i>icon-key
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-link icons font-2xl d-block mt-4"></i>icon-link
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-lock icons font-2xl d-block mt-4"></i>icon-lock
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-lock-open icons font-2xl d-block mt-4"></i>icon-lock-open
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-magnifier icons font-2xl d-block mt-4"></i>icon-magnifier
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-magnifier-add icons font-2xl d-block mt-4"></i>icon-magnifier-add
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-magnifier-remove icons font-2xl d-block mt-4"></i>icon-magnifier-remove
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-paper-clip icons font-2xl d-block mt-4"></i>icon-paper-clip
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-paper-plane icons font-2xl d-block mt-4"></i>icon-paper-plane
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-power icons font-2xl d-block mt-4"></i>icon-power
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-refresh icons font-2xl d-block mt-4"></i>icon-refresh
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-reload icons font-2xl d-block mt-4"></i>icon-reload
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-settings icons font-2xl d-block mt-4"></i>icon-settings
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-star icons font-2xl d-block mt-4"></i>icon-star
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-symbol-female icons font-2xl d-block mt-4"></i>icon-symbol-female
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-symbol-male icons font-2xl d-block mt-4"></i>icon-symbol-male
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-target icons font-2xl d-block mt-4"></i>icon-target
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-credit-card icons font-2xl d-block mt-4"></i>icon-credit-card
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-paypal icons font-2xl d-block mt-4"></i>icon-paypal
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-tumblr icons font-2xl d-block mt-4"></i>icon-social-tumblr
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-twitter icons font-2xl d-block mt-4"></i>icon-social-twitter
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-facebook icons font-2xl d-block mt-4"></i>icon-social-facebook
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-instagram icons font-2xl d-block mt-4"></i>icon-social-instagram
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-linkedin icons font-2xl d-block mt-4"></i>icon-social-linkedin
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-pinterest icons font-2xl d-block mt-4"></i>icon-social-pinterest
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-github icons font-2xl d-block mt-4"></i>icon-social-github
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-gplus icons font-2xl d-block mt-4"></i>icon-social-gplus
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-reddit icons font-2xl d-block mt-4"></i>icon-social-reddit
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-skype icons font-2xl d-block mt-4"></i>icon-social-skype
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-dribbble icons font-2xl d-block mt-4"></i>icon-social-dribbble
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-behance icons font-2xl d-block mt-4"></i>icon-social-behance
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-foursqare icons font-2xl d-block mt-4"></i>icon-social-foursqare
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-soundcloud icons font-2xl d-block mt-4"></i>icon-social-soundcloud
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-spotify icons font-2xl d-block mt-4"></i>icon-social-spotify
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-stumbleupon icons font-2xl d-block mt-4"></i>icon-social-stumbleupon
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-youtube icons font-2xl d-block mt-4"></i>icon-social-youtube
        </div>
        <div class="col-6 col-sm-4 col-md-3">
          <i class="icon-social-dropbox icons font-2xl d-block mt-4"></i>icon-social-dropbox
        </div>
      </div>
      <!--/.row-->
    </div>
  </div>
</div>
