import { Component } from '@angular/core';

@Component({
  templateUrl: 'simple-line-icons.component.html'
})
export class SimpleLineIconsComponent {

  constructor() { }

}
