import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import Cookies from '../utils/cookies';
import { LicenseService } from '../services/license.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        public authService: AuthService, 
        public licenseService: LicenseService,
        public router: Router,
    ) { }

    intercept(oldRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const request = oldRequest.clone({
            withCredentials: true,
        })

        return next.handle(request).pipe(
            catchError((err) => {
                (async () => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            let ignoreErr = false;
                            try {
                                const license = await this.licenseService.getLicense()
                                if (license.license == "") {
                                    if (license.self) {
                                        this.router.navigate(['/buy'])
                                    } else {
                                        this.router.navigate(['/no-license'])
                                    }
                                    ignoreErr = true
                                }
                            } catch(e) {
                                console.error("Authentication error")
                            }
                            if(!ignoreErr) {
                                Cookies.remove('Authentication')
                                this.authService.login()
                            }
                        }
                    }
                })()
                return throwError(err)
            })
        )
    }
}