import * as jsCookie from 'js-cookie';
import { environment } from '../../environments';

export namespace Cookies {
    export function get(name: string): string | undefined {
        return jsCookie.get(name)
    }

    export function remove(name: string, options?: jsCookie.CookieAttributes): void {
        jsCookie.remove(name, options)
    }

    export function set<T extends object = object>(name: string, value: string | T, options?: jsCookie.CookieAttributes): string | undefined {
        return jsCookie.set(name, value, {
            domain: environment.BASE_COOKIE,
            secure: true,
            sameSite: "Strict",
            ...(options || {}),
        })
    }
}

export default Cookies;
